:root {
  --f7-page-bg-color:#EFF4F9;
  --f7-list-item-text-max-lines: 12;
}

.toolbar-inner {
  max-width: 440px;
  margin-left: auto;
  margin-right: auto;
  right: 0;
  left: 0;
}

.functions-page-icon {
  text-align: center !important;
  color: #2b3452;
  width: 100% !important;
  margin-top: 32px;
}